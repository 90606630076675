<template>
  <div id="feedetail">
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div class="banner-inner fee-detail">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title text-uppercase">{{ $t('Fee Details') }}</h1>
              <h3 class="article-content">{{ $t('Find out more about membership fees for standard registration.') }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5 box-fee-detail">
        <div class="mt-5">
            <p>{{ $t('Here are the details for standard registration and certification fees for a yoga teacher, yoga school or studio partnership. Kindly note that all registration fees are non-refundable. Therefore, please be certain of your registration before you proceed with the payment.') }}</p>
            <p>We offer both Annual and Lifetime registration for Yoga Schools & Teachers, however, failure to comply with our regulations may result in termination of your membership. Please refer to our <router-link :to="{ name:'termsAndConditions' }">Terms & Conditions</router-link> for more information.</p>
        </div>
        <hr class="mt-5 mb-5">
        <div class="mt-5">
            <h1 class="section-header-title text-uppercase"><strong>{{ $t('Standard registration fees') }}</strong></h1>
            <!-- <p>{{ $t('Please confirm that your application has been approved by WYA official team prior submitting the payment.') }}</p> -->
        </div>
        <div class="mt-5 d-none">
          <h6>{{ $t('Switch type of Currency') }} ({{ $t('USD') }} {{ $t('or') }} {{ $t('THB') }})?</h6>
          <b-form-checkbox v-model="checked" name="check-button" switch>
          <b>{{ checked ? $t('THB') : $t('USD') }}</b>
          </b-form-checkbox>
        </div>
        <div class="mt-3">
          <b-table-simple caption-top responsive bordered class="table-default">
            <caption class="schools">{{ $t('For Schools') }}</caption>
            <b-thead>
              <b-tr>
                <b-th rowspan="2" variant="secondary"></b-th>
                <b-th rowspan="2" class="text-center" variant="secondary" >{{ $t('Description') }}</b-th>
                <b-th class="text-center" variant="secondary">{{ $t('Lifetime') }}</b-th>
                <b-th class="text-center" variant="secondary">{{ $t('Annual') }}</b-th>
                <!-- <b-th colspan="2" class="text-center" variant="secondary" >{{ $t('Type of Membership')}} </b-th> -->
              </b-tr>
              <b-tr>
                <b-th class="text-center" variant="secondary">{{  currentCurrency.toUpperCase() }}</b-th>
                <b-th class="text-center" variant="secondary">{{ currentCurrency.toUpperCase() }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                  <b-td rowspan="4">{{ $t('Level of registration') }}</b-td>
                  <b-td >{{ $t('16-100 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[0] | numeral('0,0') }}</b-td>
                  <b-td rowspan="6" class="text-center">{{ AnnualForSchools | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('101-200 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[1] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('201-300 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[2] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('301-500 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[3] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('Any 2 Level of registrations') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[4] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('More than 3 Level of registrations') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForSchools[5] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="4"><b>Upgrade</b> {{ $t('(for current lifetime member ONLY)') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('OYCP standards') }}</b-td>
                  <b-td colspan="1" class="text-center">{{ lifetimeForSchools[6] | numeral('0,0') }}</b-td>
                  <b-td colspan="1" class="text-center" variant="secondary"></b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('Additional training (same designation)') }}</b-td>
                  <b-td colspan="1" class="text-center">{{ lifetimeForSchools[6] | numeral('0,0') }}</b-td>
                  <b-td colspan="1" class="text-center" variant="secondary"></b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('Additional designation') }}</b-td>
                  <b-td colspan="1" class="text-center">{{ $t('30% discount from standard fee') }}</b-td>
                  <b-td colspan="1" class="text-center" variant="secondary"></b-td>
                </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top bordered responsive>
            <caption class="teachers">{{ $t('For Teachers') }}</caption>
            <b-thead>
              <b-tr>
                <b-td rowspan="2" variant="secondary"></b-td>
                <b-th rowspan="2" class="text-center"  variant="secondary">{{ $t('Description') }}</b-th>
                 <b-th class="text-center"  variant="secondary">{{ $t('Lifetime') }}</b-th>
                <b-th class="text-center"  variant="secondary">{{ $t('Annual') }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center"  variant="secondary">{{ currentCurrency.toUpperCase() }}</b-th>
                <b-th class="text-center"  variant="secondary">{{ currentCurrency.toUpperCase() }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr>
                  <b-td rowspan="5"  class="text-center">{{ $t('Level of registration') }}</b-td>
                  <b-td >{{ $t('16-50 Hour') }}</b-td>
                  <b-td class="text-center"> {{ lifetimeForTeachers[0] | numeral('0,0')  }}</b-td>
                  <b-td rowspan="6" class="text-center">{{ AnnualForTeachers | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('51-100 Hour') }}</b-td>
                  <b-td class="text-center"> {{ lifetimeForTeachers[1] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('101-200 Hour') }}</b-td>
                  <b-td class="text-center"> {{ lifetimeForTeachers[2] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('201-300 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForTeachers[3] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('301-500 Hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForTeachers[4] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('Upgrade / additional hour') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForTeachers[5] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td colspan="2">{{ $t('Experienced Yoga Teacher') }}</b-td>
                  <b-td class="text-center">{{ lifetimeForTeachers[6] | numeral('0,0') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td rowspan="2">{{ $t('Bundle') }}</b-td>
                  <b-td >{{ $t('School + Teacher registrations') }}</b-td>
                  <b-td colspan="2" class="text-center">{{ $t('20% OFF from total standard fees') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('School + Experienced Teacher registration') }}</b-td>
                  <b-td colspan="2" class="text-center">{{ $t('25% OFF from total standard fees') }}</b-td>
                </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="mt-5 d-none">
          <h6>{{ $t('Switch type of Membership') }} ({{ $t('Annual') }} {{ $t('or') }} {{ $t('Lifetime') }})?</h6>
          <b-form-checkbox v-model="checked" name="check-button" switch>
          <b>{{ checked ? $t('Lifetime') : $t('Annual') }}</b>
          </b-form-checkbox>
        </div>
        <div v-if="!checked" class="mt-5 d-none">
          <b-tabs content-class="mt-3" pills card>
            <b-tab :title="$t('For Schools')" active>
              <b-table-simple hover small caption-top responsive bordered class="table-fee">
              <b-thead>
                <b-tr>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Description') }}</b-th>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Type')}} </b-th>
                  <b-th colspan="4" class="text-center" variant="primary">{{ $t('Price')}} </b-th>
                  <b-th rowspan="2" colspan="2" class="text-center" variant="primary">{{ $t('Remark')}} </b-th>
                </b-tr>
                <b-tr>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in ERO (€)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in USD ($)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in THB (฿)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in INR (₹)') }}</b-th>
                </b-tr>
              </b-thead>
               <b-tbody>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 16-100 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '5000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 101-200 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '5000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 201-300 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '5000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 301-500 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Any 2 Level of registrations') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '5000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('More than 3 Level of registrations') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '199' }}</b-td>
                    <b-td class="text-center">{{ '5000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center"></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Upgrade / additional training or other designations / OYCP') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '189' }}</b-td>
                    <b-td class="text-center">{{ '5700' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td colspan="2" class="text-center">{{ $t('per training') }}</b-td>
                  </b-tr>
               </b-tbody>
              </b-table-simple>
            </b-tab>
            <b-tab :title="$t('For Teachers')">
              <b-table-simple hover small caption-top responsive bordered class="table-fee">
              <!-- <caption>{{ $t('For Schools') }}</caption> -->
              <b-thead>
                <b-tr>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Description') }}</b-th>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Type')}} </b-th>
                  <b-th colspan="4" class="text-center" variant="primary">{{ $t('Price')}} </b-th>
                  <b-th rowspan="2" colspan="2" class="text-center" variant="primary">{{ $t('Remark')}} </b-th>
                </b-tr>
                <b-tr>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in ERO (€)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in USD ($)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in THB (฿)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in INR (₹)') }}</b-th>
                </b-tr>
              </b-thead>
               <b-tbody>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 101-200 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '99' }}</b-td>
                    <b-td class="text-center">{{ '3000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 201-300 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '99' }}</b-td>
                    <b-td class="text-center">{{ '3000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 301-500 Hour') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '99' }}</b-td>
                    <b-td class="text-center">{{ '3000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Experienced Yoga Teacher') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '99' }}</b-td>
                    <b-td class="text-center">{{ '3000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Bundle หกดหกดหกด + Teacher registrations') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ $t('Price % OFF from total standard fees') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Bundle School + Experienced Teacher registration') }}</b-td>
                    <b-td >{{ $t('Annual') }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ $t('Price % OFF from total standard fees') }}</b-td>
                  </b-tr>
               </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else class="mt-5 d-none">
          <b-tabs content-class="mt-3" pills card>
            <b-tab :title="$t('For Schools')" active>
              <b-table-simple hover small caption-top bordered responsive class="table-fee">
              <b-thead>
                <b-tr>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Description') }}</b-th>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Type')}} </b-th>
                  <b-th colspan="4" class="text-center" variant="primary">{{ $t('Price')}} </b-th>
                  <b-th rowspan="2" colspan="2" class="text-center" variant="primary">{{ $t('Remark')}} </b-th>
                </b-tr>
                <b-tr>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in ERO (€)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in USD ($)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in THB (฿)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in INR (₹)') }}</b-th>
                </b-tr>
              </b-thead>
               <b-tbody>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 16-100 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '599' }}</b-td>
                    <b-td class="text-center">{{ '19000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 101-200 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ $t('TBA') }}</b-td>
                    <b-td class="text-center">{{ $t('749') }}</b-td>
                    <b-td class="text-center">{{ $t('22900') | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ $t('TBA') }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 201-300 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '899' }}</b-td>
                    <b-td class="text-center">{{ '27500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 301-500 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '999' }}</b-td>
                    <b-td class="text-center">{{ '30500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Any 2 Level of registrations') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '999' }}</b-td>
                    <b-td class="text-center">{{ '30500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('More than 3 Level of registrations') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '1299' }}</b-td>
                    <b-td class="text-center">{{ '39500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Upgrade / additional training or other designations / OYCP') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '189' }}</b-td>
                    <b-td class="text-center">{{ '5700' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td colspan="2" class="text-center">{{ $t('per training') }}</b-td>
                  </b-tr>
               </b-tbody>
              </b-table-simple>
            </b-tab>
            <b-tab :title="$t('For Teachers')">
              <b-table-simple hover small caption-top bordered responsive class="table-fee">
              <b-thead>
                <b-tr>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Description') }}</b-th>
                  <b-th rowspan="2" class="text-center" variant="primary">{{ $t('Type')}} </b-th>
                  <b-th colspan="4" class="text-center" variant="primary">{{ $t('Price')}} </b-th>
                  <b-th rowspan="2" colspan="2" class="text-center" variant="primary">{{ $t('Remark')}} </b-th>
                </b-tr>
                <b-tr>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in ERO (€)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in USD ($)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in THB (฿)') }}</b-th>
                  <b-th class="text-center" variant="primary">{{ $t('Fee in INR (₹)') }}</b-th>
                </b-tr>
              </b-thead>
               <b-tbody>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 101-200 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '279' }}</b-td>
                    <b-td class="text-center">{{ '8500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 201-300 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '349' }}</b-td>
                    <b-td class="text-center">{{ '10500' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Level of registration 301-500 Hour') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '499' }}</b-td>
                    <b-td class="text-center">{{ '15000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Experienced Yoga Teacher') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ '559' }}</b-td>
                    <b-td class="text-center">{{ '17000' | numeral('0,0')}}</b-td>
                    <b-td class="text-center">{{ 'TBA' }}</b-td>
                    <b-td class="text-center">{{ $t('One Time') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Bundle School + Teacher registrations') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ $t('20% OFF from total standard fees') }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td >{{ $t('Bundle School + Experienced Teacher registration') }}</b-td>
                    <b-td >{{ $t('Lifetime') }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ '20%' }}</b-td>
                    <b-td class="text-center">{{ $t('20% OFF from total standard fees') }}</b-td>
                  </b-tr>
               </b-tbody>
              </b-table-simple>
            </b-tab>
          </b-tabs>
        </div>
        <div class="mt-5">
          <h3>{{ $t('Acceptable Payment Methods:')}}</h3>
          <p><strong>{{ $t('Bank Transfer') }}</strong></p>
          <p>{{ $t('All payments via Bank Transfer must be the net amount of the registration fee with transfer fee included. (If any) Please attach bank slip as proof of your payment.') }}</p>
          <p><strong>{{ $t('Credit Card') }}</strong></p>
          <p>{{ $t('Credit card payment option will be available soon.') }}</p>
          <!-- <p>{{ $t('Installment payment plans are available for Lifetime membership registrations only.') }}</p> -->
          <p><strong>{{ $t('PayPal') }}</strong></p>
          <p>{{ $t('4.9 % PayPal surcharge will be applied for each Paypal transaction.') }}</p>
          <p><strong>{{ $t('Western Union or MoneyGram:') }}</strong></p>
          <p>{{ $t('Due to Indian government restrictions regarding an international money transfer from foreign countries, all payments via Western Union or MoneyGram will be made to WYA Representative Division in Thailand. Please attach proof of payment so we can track and confirm.') }}</p>
        </div>
        <div class="mt-5">
          <h3>{{ $t('NOTE:') }}</h3>
          <p>{{ '📌 ' + $t('Annual membership valids for 12 months from the date of registration.') }}</p>
          <!-- <p>{{ '📌 '+ $t('In order to apply for lifetime membership, please make sure you are currently non-affiliated with other yoga alliances. ') }}</p> -->
          <p>{{ '📌 ' + $t('Kindly note that all payments are non-refundable. Please make sure you have read and agreed to our') }} <router-link :to="{ name: 'termsAndConditions' }">{{ $t('Terms and Conditions') }}</router-link> {{ ('before submitting any payments. WYA has rights to terminate your membership with no refund if found any violations against our Terms.') }}</p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TermsAndContions',
  title: ' | Terms and Contions',
  data () {
    return {
      loading: true,
      checked: false
    }
  },
  computed: {
    currentCurrency () {
      return this.$store.getters.currentCurrency
    },
    lifetimeForSchools () {
      switch (this.currentCurrency) {
        case 'usd' : return [599, 749, 899, 999, 999, 1299, 189]
        case 'eur' : return [495, 620, 740, 825, 825, 1070, 155]
        case 'thb' : return [19000, 22900, 27500, 30500, 30500, 39500, 5700]
        case 'inr' : return [44000, 56000, 65900, 73000, 73000, 95000, 13900]
        default : return [599, 749, 899, 999, 999, 1299, 899]
      }
    },
    AnnualForSchools () {
      switch (this.currentCurrency) {
        case 'usd' : return 199
        case 'eur' : return 165
        case 'thb' : return 5000
        case 'inr' : return 14900
        default : return 199
      }
    },
    lifetimeForTeachers () {
      switch (this.currentCurrency) {
        case 'usd' : return [110, 149, 279, 349, 499, 89, 599]
        case 'eur' : return [90, 125, 235, 290, 415, 75, 495]
        case 'thb' : return [3500, 4500, 8500, 10500, 15000, 2800, 18000]
        case 'inr' : return [8000, 11000, 20500, 26000, 37000, 6700, 44000]
        default : return [110, 149, 279, 349, 499, 89, 599]
      }
    },
    AnnualForTeachers () {
      switch (this.currentCurrency) {
        case 'usd' : return 99
        case 'eur' : return 85
        case 'thb' : return 3000
        case 'inr' : return 7000
        default : return 99
      }
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    // this.currentCurrency = this.$store.getters.currentCurrency.toUpperCase()
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
<style scoped>
  #feedetail {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
.rotate {

  transform: rotate(-90deg);
  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

}
</style>
